<template>
    <section id="contact">
        <div class="container">
            <div class="title-effect position-relative">
                <h1 class="text-center title-text p-2">FORMULAIRE DE CONTACT</h1>
                <div class="background-text text-center">CONTACT</div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 border-right">
                            <h2 class="mb-4">CPTS **** ****</h2>
                            <div class="d-flex flex-column justify-content-around align-items-around">
                                <p><i class="icon icon-location-pin"></i> Adresse <br> Code Postal - Ville</p>
                                <p><i class="icon icon-phone"></i> *** *** **** </p>
                                <p><i class="fa fa-paper-plane-o"></i> cpts.mail@gmail.com</p>
                            </div>
                            <div>Carte</div>
                        </div>
                        <div class="col-md-6">
                            <h3 class="mb-4">Pour toutes les informations:</h3>
                            <b-form-group label="Nom">
                                <b-form-input placeholder="Nom" class="col-md-6 col-sm-12"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Prénom">
                                <b-form-input placeholder="Prénom" class="col-md-8 col-sm-12"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Email">
                                <b-form-input placeholder="Email" class="col-md-8 col-sm-12"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Objet du message">
                                <b-form-input placeholder="Objet du message" class="col-md-8 col-sm-12"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Message">
                                <b-form-textarea placeholder="Votre message"></b-form-textarea>
                            </b-form-group>
                            <hr>
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-primary">Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"contact"
}
</script>
<style scoped>
#contact .fa, #contact .icon {
    font-size: 2rem;
}
.card {
    box-shadow: 0px 4px 9px rgb(144, 144, 144);
}
</style>